[
  {
    "Position": "QA engineer Mid",
    "Employer": "Reshape Digital",
    "Description": "This position was more technical than the last one. From querying the DB to analysing network calls more efficiently, this position has really boosted  my technical knowledge.",
    "date": "2024 Jan - Present "
  },

  {
    "Position": "QA engineer Junior",
    "Employer": "Linnify",
    "Description": "A pivotal moment in my career. I learned here most of my hard-skills. Working in a team and growing were amazing benefits.",
    "date": "2022 Aug - 2023 Nov "
  },

  {
    "Position": "Tester",
    "Employer": "QuanticLab",
    "Description": "Lerned to write testcase, run regressions, test runs and report findings.",
    "date": "2022 Feb - 2022 Aug"
  },
  {
    "Position": "Automation course",
    "Employer": "Azimut Vision",
    "Description": "This is where I learned how to run my first automation test using Java and Selenium. A lot has changed since then..",
    "date": "2021 Apr - 2021 Oct"
  }
]
